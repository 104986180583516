import React, { useState, useEffect } from 'react';
import { FeatureGroup, GeoJSON, MapContainer, TileLayer, LayersControl } from 'react-leaflet';
import EditControl from './components/maps/EditControl';
import bbox from 'geojson-bbox';
import { uniqFeatures } from "./utilities";
import ActivityApplicationsTable from './components/maps/ActivityApplicationsTable';
import SearchableSelect from './components/SearchableSelect';

export default function FieldWorkerSubForm({ field, activity, activityId, index }) {
  const extent = bbox(field); 
  const [drawEnabled, setDrawEnabled] = useState(false)
  const [features, xSetFeatures] = useState([])

  const setFeatures = (list) => {
    xSetFeatures(uniqFeatures(list))
  }

  useEffect(() => {
    const val = JSON.stringify({ type: 'Feature Collection', features })
    if (val && index) {
      document.getElementById(`activity_sheet_activity_applications_attributes_${index}_geo_json`).value = val;
    }
  }, [JSON.stringify(features)])

  return(
    <>
      <SearchableSelect
        optionsUrl="/equipment/by_client?secondary=vehicle"
        label="Vehicle"
        inputId="activity_sheet_activity_applications_attributes_0_actual_activities_attributes_0_vehicle_id"
        inputName="activity_sheet[activity_applications_attributes][0][actual_activities_attributes][0][vehicle_id]"
      />
      <SearchableSelect
        optionsUrl="/equipment/by_client?secondary=implement"
        label="Implementation"
        inputId="activity_sheet_activity_applications_attributes_0_actual_activities_attributes_0_implement_id"
        inputName="activity_sheet[activity_applications_attributes][0][actual_activities_attributes][0][implement_id]"
      />
      <input
        type="hidden"
        className="string optional span12"
        value={JSON.stringify(features)}
        id="activity_sheet_activity_applications_attributes_0_actual_activities_attributes_0_geo_json"
        name="activity_sheet[activity_applications_attributes][0][actual_activities_attributes][0][implement_id]"
      />
      <MapContainer
        bounds={[[extent[1],extent[0]], [extent[3], extent[2]]]}
        scrollWheelZoom
        style={{ height: '420px' }}
      >
        <TileLayer
          url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
          maxZoom= {20}
          subdomains={['mt1','mt2','mt3']}
        />
        <LayersControl position="topright">
          <LayersControl.Overlay checked name="Field Outline">
            <GeoJSON data={field} pathOptions={{ color: '#6988E7', fillOpacity: 0 }}/>
          </LayersControl.Overlay>
          <LayersControl.Overlay checked name="Planned">
            <GeoJSON data={activity} pathOptions={{ color: 'yellow', fillOpacity: 0 }}/>
          </LayersControl.Overlay>
        </LayersControl>
        <FeatureGroup key={JSON.stringify(features)} pathOptions={{ color: 'orange' }}>
          <EditControl
            edit={{ edit: false, remove: false, }}
            position='bottomleft'
            features={features}
            skipFitBoundsOnChange
            setFeatures={setFeatures}
            draw={{ 
              circlemarker: false, 
              marker: false, 
              circle: false, 
              polyline: false,
              rectangle: drawEnabled ? { shapeOptions: { color: 'orange', opacity: 1 } } : null,
              polygon: drawEnabled ? { shapeOptions: { color: 'orange', opacity: 1 } } : null
            }}
          />
        </FeatureGroup>
      </MapContainer>
      <ActivityApplicationsTable 
        setDrawEnabled={setDrawEnabled}
        activityId={activityId}
        features={features}
        setFeatures={xSetFeatures}
        field={field}
      />
    </>
  )
}
