import React, { useState, useEffect } from 'react';
import Select from 'react-select'

export default function OperationFarmSubForm({ sites, farms, farmId, siteId }) {
  const [site, setSite] = useState()
  const [farm, setFarm] = useState()

  const siteOptions = sites
  .sort((a, b) => a.name - b.name)
  .map(s => ({ value: s.id, label: s.name}));

  const farmOptions = farms
    .sort((a, b) => a.name - b.name)
    .filter(f => f.site_id === site?.value)
    .map(f => ({ value: f.id, label: `${site.label} -- ${f.name}`}));

  useEffect(() => {
    const farm = farms.find(f => f.id === farmId)
    const site = sites.find(s => s.id === siteId)
    if (farm) setFarm({ value: farm.id, label: `${site.name} -- ${farm.name}`})
    if (site) setSite({ value: site.id, label: site.name})
  }, [])

  return (
    <>
      <div className="control-group select optional nutrient_application_report_product">
        <label className="select optional control-label" for="nutrient_application_report_product_id">
          Operation(required)
        </label>
        <div className="controls">
          <Select id="site" name="site" options={siteOptions} onChange={setSite} value={site} isClearable />
        </div>
      </div>
      <div className="control-group select optional nutrient_application_report_product">
        <label style={{width: '160px' }} className="select optional control-label" for="nutrient_application_report_product_id">
          Farm(required)
        </label>
        <div className="controls">
          <Select id="field_farm_id" name="field[farm_id]" options={farmOptions} onChange={setFarm} value={farm} isClearable />
        </div>
      </div>
    </>
  )
}