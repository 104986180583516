import React, { useEffect, useState, useRef } from "react";
import geojsonArea from '@mapbox/geojson-area';

import { MapContainer, TileLayer, FeatureGroup } from 'react-leaflet';
import { EditControl } from "react-leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import bbox from 'geojson-bbox';
import GpsAcresInput from "./components/maps/GpsAcresInput";
import LegalInput from './components/maps/LegalInput';
import ShpFileInput from "./components/maps/ShpFileInput";
import ZoomTo from './components/maps/ZoomTo';

function extractLayers(ref) {
  let layers = [];

  ref.current.eachLayer((layer) => {
    layers.push(layer.toGeoJSON());
  })

  return layers;
}

export default function MarkableMap({ field }) {
  const fieldRef = useRef();
  const initialFieldGeoJson = JSON.parse(field.geo_json);
  const [features, xSetFeatures] = useState(initialFieldGeoJson?.features || [])

  const [gpsAcres, setGpsAcres] = useState(document.getElementById('field_gps_acres').value || null)
  const [legalInputVal, setLegalInputVal] = useState({
    legal1: field.legal_1,
    legal2: field.legal_2,
    range: field.legal_range,
    range_direction: field.legal_range_dir,
    section: field.legal_sec,
    state: field.legal_state,
    township: field.legal_township,
    township_direction: field.legal_township_dir
  })
  const [legalAddressRecord, setLegalAddressRecord] = useState([]);

  useEffect(() => {
    document.getElementById('field_geo_json').value = JSON.stringify({ type: 'FeatureCollection', features });
  }, [JSON.stringify(features)])

  useEffect(() => {
      document.getElementById('field_gps_acres').value = gpsAcres;
      document.getElementById('field_spreadable_acres').value = gpsAcres;
  }, [gpsAcres])

  const extent = initialFieldGeoJson ? bbox(initialFieldGeoJson) : null;

  const mapContainerProps = extent
    ? { bounds: [[extent[1], extent[0]], [extent[3], extent[2]]] }
    : { zoom: 10, center: [41.73583039, -95.70333052] }
    
  // this will not trigger on the initial layer load, only for user based inputs.
  const onUpdate = () => {
    // map updates
    const newFeatures = extractLayers(fieldRef);
    xSetFeatures(newFeatures);

    // gps calculation
    const list = newFeatures.map((f) => {
      return geojsonArea.geometry(f.geometry) / 4046.85642})
    const calculated = list.reduce((partialSum, a) => partialSum + a, 0)

    const value = calculated.toFixed(2);
    setGpsAcres(value)
  };

  const onChangeFile = (newFeatures) => {
    xSetFeatures(newFeatures);

    // gps calculation
    const list = newFeatures.map((f) => {
    return geojsonArea.geometry(f.geometry) / 4046.85642})
    const calculated = list.reduce((partialSum, a) => partialSum + a, 0)

    const value = calculated.toFixed(2);
    setGpsAcres(value)
  };

  // add existing layers to the map
  const onMounted = () => {
    // this layer will never exist on the map, it is only used to parse the geojson layers.
    let leafletGeoJSON = new L.GeoJSON({ type: 'FeatureCollection', features });
    let leafletFG = fieldRef.current;

    leafletGeoJSON.eachLayer(layer => {
      leafletFG.addLayer(layer)
    });
  };

  return (
    <>
    <GpsAcresInput
      gpsAcres={gpsAcres}
      setGpsAcres={setGpsAcres}
    />
    <MapContainer
      {...mapContainerProps}
      scrollWheelZoom
      style={{ height: '420px' }}
    >
        <TileLayer
          url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
          maxZoom={20}
          subdomains={['mt1', 'mt2', 'mt3']}
        />
        <ZoomTo legalAddressRecord={legalAddressRecord} legalInputVal={legalInputVal} />
        {/* <FeatureGroup key={JSON.stringify(features)} pathOptions={{ color: 'yellow' }}> */}
        <FeatureGroup key={JSON.stringify(features)} pathOptions={{ color: '#6988E7' }} ref={fieldRef}>
          <EditControl
            position='bottomleft'
            draw={{ circlemarker: false, marker: false, circle: false, polyline: false, rectangle: false }}
            onCreated={onUpdate}
            onDeleted={onUpdate}
            onEdited={onUpdate}
            onMounted={onMounted}
          />
        </FeatureGroup>
      </MapContainer>
      <ShpFileInput features={features} setFeatures={onChangeFile} />
      <LegalInput
        setLegalAddressRecord={setLegalAddressRecord}
        legalAddressRecord={legalAddressRecord}
        legalInputVal={legalInputVal}
        setLegalInputVal={setLegalInputVal}
      />
    </>
  );
}