import React, { useState } from 'react';
import geojsonArea from '@mapbox/geojson-area';

export default function ActivityApplicationsTable({
  features,
  setDrawEnabled,
  activityId,
  setFeatures,
  field,
}) {
  const [errors, setErrors] = useState([])
  const [isPending, setIsPending] = useState(false)
  const [asAppliedActive, setAsAppliedActive] = useState(false)

  const removeFeature = (indexToRemove) => {
    const result = [...features.slice(0, indexToRemove), ...features.slice(indexToRemove + 1)];
    setFeatures(result)
  }

  const setWholeField = () => {
    setFeatures(field.features)
  }

  const csrfToken = document.querySelector("[name='csrf-token']").content

  const options = {
    method: 'PATCH',
    headers: { "X-CSRF-Token": csrfToken, "content-type": 'application/json' },
    body: JSON.stringify({ app_geo_json: JSON.stringify({ type: 'Feature Collection', features }) })
  };

  const checkStatus = async (response) => {
    if (response.status >= 200 && response.status < 300) 
      return await response.json()

    throw await response.json()
  }

  function updateData() {
    setIsPending(true)
    fetch(`/api/activities/${activityId}/geo_json`, options)
    .then(checkStatus)
    .then(r => {
      const stringGeoJson = JSON.parse(r.activity).app_geo_json
      const geoJson = JSON.parse(stringGeoJson)
      resetFeaturesFromBackend(geoJson.features)
    })
    .catch((e) => setErrors(e.errors))
    .finally(() => setIsPending(false))
  }

  return(
    <div style={{border: '1px solid black'}}>
      <button 
        style={{
          marginLeft: '5px',
          padding: '0px',
          background: 'none',
          border: 'none',
          color: '#069',
          cursor: 'pointer',
          marginRight: '5px'
        }}
        onClick={(e) => {
          e.preventDefault()
          setDrawEnabled(false)
          setAsAppliedActive(false)
          setWholeField()
        }}
      >
        whole field
      </button>
      |
      <button 
        style={{
          marginLeft: '5px',
          marginRight: '5px',
          padding: '0px',
          background: 'none',
          border: 'none',
          color: '#069',
          cursor: 'pointer',
        }}
        onClick={(e) => {
          e.preventDefault()
          setDrawEnabled(false)
          setAsAppliedActive(true)
        }}
      >
        as applied
      </button>
      |
      <button 
        style={{
          marginLeft: '5px',
          padding: '0px',
          background: 'none',
          border: 'none',
          color: '#069',
          cursor: 'pointer',
        }}
        onClick={(e) => {
          e.preventDefault()
          setDrawEnabled(true)
          setAsAppliedActive(false)
        }}
      >
        draw
      </button>
      {
        features.length 
        ? (
          <>
            <h4>Activity Sections List</h4>
            <table className="table table-striped table-sm">
              <thead>
                <tr>
                  <th scope="col">Section #</th>
                  <th scope="col">Acres</th>
                  <th scope="col">Remove</th>
                </tr>
              </thead>
              <tbody>
                {
                  features.map((f, i) => (
                    <tr key={`featurerow${i}`}>
                    <th scope="row">{i + 1}</th>
                    <td>{(geojsonArea.geometry(f.geometry) / 4046.85642).toFixed(2)}</td>
                    <td>
                      <button 
                        style={{
                          marginLeft: '5px',
                          padding: '0px',
                          background: 'none',
                          border: 'none',
                          color: '#069',
                          cursor: 'pointer',
                        }}
                        onClick={(e) => {
                          e.preventDefault()
                          removeFeature(i)
                        }}
                      >
                        remove
                      </button>
                    </td>
                  </tr>
                  ))
                }
              </tbody>
            </table>
          </>
        )
        : null
      }



          {
            errors.length
            ? (
              <div style={{border: '1px solid red', margin: "5px 2px", padding: '2px'}}>
                <ul>
                  {errors.map((e) => (<li key={e} style={{color: 'red'}}>{e}</li>))}
                </ul>
              </div>
            )
            : null
          }
          {
            asAppliedActive
            ? (
              <div style={{ padding: '5px', display: 'flex', justifyContent: 'flex-end' }}>
                <button 
                  onClick={(e) => {
                    e.preventDefault()
                  }}
                  type="button"
                >
                    {isPending ? 'Loading As Applied Dataing...' : 'Load As Applied Data'}
                </button>
              </div>
            )
            : null
          }
    </div>
  )
}
