export default function GpsAcresInput({ setGpsAcres, gpsAcres }) {
  return(
    <div className="control-group float optional field_gps_acres">
      <label className="float optional control-label" htmlFor="field_gps_acres_js">
        GPS Acres
      </label>
      <div className="controls">
        <input 
          className="numeric float optional span12 span12" 
          type="number"
          step="any" 
          onChange={(e) => setGpsAcres(e.target.value)}
          value={gpsAcres}
          id="field_gps_acres_js"
          />
      </div>
    </div>
  )
}