import React from 'react';
import { GeoJSON, MapContainer, TileLayer, Rectangle } from 'react-leaflet';
import bbox from 'geojson-bbox';
import AgMarker from '../images/ag_mark_small-2x.png'
import L from 'leaflet';

const icon = L.icon({ iconUrl: AgMarker, iconAnchor: [15, 35] });

export default function FieldMap({ fieldOutline, legalBounds }) {
  const containerProps = {};
  try {
    const extent = bbox(fieldOutline);
    containerProps['bounds'] = [[extent[1],extent[0]], [extent[3], extent[2]]]
  } catch (exceptionVar) {
    containerProps['center'] = [41.73583039, -95.70333052];
    containerProps['zoom'] = 10;
  }

  function createMarker(feature, latlng) {
    return L.marker(latlng, { icon });
  }

  return (
    <MapContainer
      scrollWheelZoom
      style={{ height: '420px' }}
      {...containerProps}
    >
      <TileLayer
        url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
        maxZoom= {20}
        subdomains={['mt1','mt2','mt3']}
      />
      <GeoJSON data={fieldOutline} pathOptions={{ color: '#6988E7' }} pointToLayer={createMarker} />
      <Rectangle bounds={legalBounds} pathOptions={{ color: 'red', fillOpacity: 0, weight: 2 }} />
    </MapContainer>
  );
}
